/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

body{
  height:100dvh;
}

.fade-in {
  animation: fadeIn 1s ease-in-out forwards;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.photo-stack {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.photo {
  margin: 10px;
     /* Limits the width to the parent container's width */
    max-height: 400px;  /* Limits the height similarly */
    width: auto;       /* Allows natural width */
    height: auto;      /* Allows natural height */
  transition: transform 0.2s; /* Smooth transform effect on hover */
  
}

.photo:hover {
  transform: scale(1.05); /* Slightly enlarge the photos on hover */
}

.full-screen-background {
  height: 100dvh; /* 100% of the viewport height */

  width: 100vw; /* 100% of the viewport width */
  background: url('20240403-999.jpg') no-repeat center fixed;
  background-size: cover;
  display: flex;
  align-items: center; /* Centers the title vertically */
  justify-content: flex-start; /* Aligns the title to the left */
  cursor: pointer; /* Makes the mouse cursor indicate clickable area */
}

@media (max-width: 700px) {
  .full-screen-background {
    height: 100dvh; /* 100% of the viewport height */
    width: 100vw; /* 100% of the viewport width */
    background: url('mobile_landing2.jpg') no-repeat center fixed;
    background-size: cover;
    display: flex;
    align-items: center; /* Centers the title vertically */
    justify-content: flex-start; /* Aligns the title to the left */
    cursor: pointer; /* Makes the mouse cursor indicate clickable area */
  }
  .centered-title {
    margin-left:25px !important;
    font-size: 1.6rem !important;
    font-weight: 400;
  }
}

.centered-title {
  margin-left: 60px; 
  font-size: 2em; 
  color: white; /* Assuming a dark background image */
  font-family: 'Cormorant Garamond', sans-serif;
  font-size: 2rem;
  font-weight: 400;
}

.photo-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  width: 100vw;    /* Full viewport width */
  text-align: center;
  background-color: #fff; /* Optional: any color or leave this for default */
  font-family:'DM Mono', sans-serif;
}

.photo-viewer img {
  max-height: 50dvh;  /* Maximum height for the image */
  height: 50dvh;
  width: auto;       /* Maintain the aspect ratio */
  max-width: 100%;   /* Limit width to avoid overflow */
  object-fit: contain; /* Ensures the image is scaled correctly within constraints */
}
  .photo-viewer img {
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
  }

@media (max-width: 700px) {
  .photo-viewer img {
    max-height: 600px; /* Maximum height for the image */
    height: auto;
    width: 85vw; /* Maintain the aspect ratio */
    max-width: 85vw; /* Limit width to avoid overflow */
    object-fit: contain; /* Ensures the image is scaled correctly within constraints */
  }
    
}

.page-number, .info-link {
  margin-top: 20px;
  position: absolute;
  top: 90dvh;
  left: 50%;
  transform: translateX(-50%);
  background-position-y: 90%;
  font-size: 1.5em;
  color: #9e9e9e;
  cursor: pointer;
  font-family: 'Cormorant Garamond', sans-serif;
  font-weight:500;
  text-decoration:underline;
}

h1.info-link {
  
}

.info-link{
  height:50px !important;
  left:55vw;
}

.page-number{
  height:50px !important;
  left:45vw;
}

@media (max-width: 700px) {
  .info-link {
    height: 50px !important;
    left: 60vw;
    font-size:1em;
  }

  .page-number {
    height: 50px !important;
    left: 40vw;
    font-size:1em;
  }
}

  .grid-viewer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
    gap: 40px;
    justify-items: space-between;
    padding: 10px;
    align-items: start;
    padding: 10% 10%;
  }

  @media (max-width: 700px) {
    .grid-viewer {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
      gap: 25px;
      justify-items: space-between;
      padding: 10px;
      align-items: start;
      padding: 10% 10%;
    }
  }

  .grid-viewer img {
    width: auto;
    height: auto;
    max-width: 100%; /* Ensures image does not overflow its grid cell */
    max-height: 100dvh;
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
  }

    .grid-viewer img:hover {
      opacity: 80%; /* scale up image to 110% of its original size on hover */
    }

  .grid-cell {
    display: flex;
    align-items: center; /* Centers content vertically */
    justify-content: center; /* Centers content horizontally */
    height: 100%; /* Optional: Specify a fixed height if needed */
  }

  .info-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    text-align: center;
    background: #fff; /* Light grey background */
    color: #333; /* Dark grey text */
    cursor: pointer;
  }

    .info-page h1 {
      margin: 0.5em 0;
      font-size: 1.2em; /* 32px if the user's default font-size is 16px */
      color: #000;
      font-family: 'Cormorant Garamond', sans-serif;
      font-weight: 700;
      opacity: 0;
      animation: fadeIn 1s ease-in-out forwards;
    }


    .info-page p {
      font-size: 1em;
      color: #666; /* Lighter grey text */
      margin: 0px;
      font-family: 'Cormorant Garamond', sans-serif;
      font-weight: 500;
      opacity: 0;
      animation: fadeIn 1s ease-in-out forwards;
    }
